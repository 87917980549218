export enum MessageFromEnum {
  USER = "user",
  SYSTEM = "gpt",
}

export interface IMessage {
  from: MessageFromEnum;
  content: string;
}
export enum ConversationStatusEnum {
  NEEDS_CLASSIFICATION = "needs_classification",
  NEEDS_LLM = "needs_llm",
  IN_PROGRESS = "in_progress",
  NEEDS_USER_RESPONSE = "needs_user_response",
  RESOLVED = "resolved",
}
export interface IConversation {
  _id: string;
  msisdn: string;
  latitude: number;
  longitude: number;
  productName: string;
  damageType: string;
  image: {
    mimetype: string;
    path: string;
  };
  userGptMessages: IMessage[];
  status: ConversationStatusEnum;
}
