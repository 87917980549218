import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as api from "../apis";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { IFormData } from "./MainPage";
import { useEffect, useState } from "react";
import ConfirmDialog from "./ConfirmDialog";
import LoadingDialog from "./LoadingDialog";
import OtpDialog from "./OtpDialog";
import { isValidMsisdn } from "../helpers/functions";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function SubmitForm({
  showConversationsList,
  showChatPage,
}: {
  showConversationsList: Function;
  showChatPage: Function;
}) {
  async function startConversation() {
    try {
      setLoading(true);
      let res = await api.startConversation(
        formState.msisdn!,
        formState.position?.latitude.toString()!,
        formState.position?.longitude.toString()!,
        formState.productName!,
        formState.damageType!,
        formState.image!
      );
      setConversation(res.data.conversation);
      setShowConfirm(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  const [formState, setFormState] = useState<IFormData>({});
  const [msisdnIsValid, setMsisdnIsValid] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [conversation, setConversation] = useState<any>(null);
  const [findUserLocation, setFindUserLocation] = useState<{
    loading: boolean;
    success: boolean | null;
  }>({ loading: false, success: null });

  const [showOtpDialog, setShowOtpDialog] = useState(false);

  useEffect(() => {
    let msisdn = localStorage.getItem("msisdn");
    if (msisdn) {
      setFormState({ ...formState, msisdn });
    }
  }, []);

  return (
    <Stack spacing={2}>
      <Typography textAlign={"center"} variant="h6">
        دستیار گیاه پزشکی هوشمند
      </Typography>
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          setFindUserLocation({ loading: true, success: null });
          navigator.geolocation.getCurrentPosition(
            (position) => {
              console.log("user position", position);
              setFormState({
                ...formState,
                position: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                },
              });
              setFindUserLocation({ loading: false, success: true });
            },
            (err) => {
              console.log("get location error", err);
              setFindUserLocation({ loading: false, success: false });
            }
          );
        }}
      >
        دریافت موقعیت جغرافیایی
      </Button>
      <Paper>
        {findUserLocation.success && (
          <>
            <Typography> موقعیت جغرافیایی دریافت شده</Typography>
            <Typography>
              {formState.position?.latitude +
                " , " +
                formState.position?.longitude}
            </Typography>
          </>
        )}
        {findUserLocation.success === false && (
          <Typography>بروز خطا در دریافت موقعیت جغرافیایی</Typography>
        )}
      </Paper>

      <InputLabel id="msisdnLabel">شماره موبایل</InputLabel>
      <TextField
        value={formState.msisdn}
        required
        error={msisdnIsValid === false}
        helperText={
          msisdnIsValid === false
            ? "شماره وارد شده صحبح نیست"
            : "به عنوان مثال 09123456789"
        }
        fullWidth
        id="msisdn"
        label="شماره موبایل"
        variant="outlined"
        onChange={(e) => {
          let msisdn = e.target.value;
          if (!isValidMsisdn(msisdn)) {
            setMsisdnIsValid(false);
          } else {
            setMsisdnIsValid(true);
          }
          setFormState({
            ...formState,
            msisdn,
          });
        }}
      />
      <InputLabel id="productNameLabel">نوع کشت</InputLabel>
      <Select
        fullWidth
        required
        labelId="productName"
        id="productNameSelect"
        value={formState.productName}
        label="نوع کشت"
        onChange={(e) => {
          setFormState({
            ...formState,
            productName: e.target.value,
          });
        }}
      >
        <MenuItem value="خیار">خیار</MenuItem>
        <MenuItem value="گوجه">گوجه</MenuItem>
        <MenuItem value="گندم">گندم</MenuItem>
        <MenuItem value="چغندر">چغندر</MenuItem>
        <MenuItem value="ذرت">ذرت</MenuItem>
        <MenuItem value="سیب زمینی">سیب زمینی</MenuItem>
        <MenuItem value="پنبه">پنبه</MenuItem>
        <MenuItem value="پیاز">پیاز</MenuItem>
        <MenuItem value="شبدر">شبدر</MenuItem>
        <MenuItem value="سویا">سویا</MenuItem>
        <MenuItem value="کلزا">کلزا</MenuItem>
        <MenuItem value="کنجد">کنجد</MenuItem>
        <MenuItem value="لوبیا">لوبیا</MenuItem>
        <MenuItem value="برنج">برنج</MenuItem>
        <MenuItem value="باقلا">باقلا</MenuItem>
      </Select>

      <InputLabel id="damageTypeLabel">موضع آسیب دیده</InputLabel>
      <Select
        fullWidth
        required
        id="damageType"
        value={formState.damageType}
        label="موضع آسیب دیده"
        onChange={(e) => {
          setFormState({
            ...formState,
            damageType: e.target.value,
          });
        }}
      >
        <MenuItem value="روی برگ">روی برگ</MenuItem>
        <MenuItem value="پشت برگ">پشت برگ</MenuItem>
        <MenuItem value="ساقه">ساقه</MenuItem>
        <MenuItem value="ریشه">ریشه</MenuItem>
        <MenuItem value="دانه">دانه</MenuItem>
        <MenuItem value="میوه">میوه</MenuItem>
        <MenuItem value="بوته">بوته</MenuItem>
      </Select>
      <Button component="label" variant="contained" tabIndex={-1}>
        <Typography sx={{ marginLeft: 1 }}>
          ارسال عکس از موضع آسیب دیده
        </Typography>
        <CloudUploadIcon />
        <VisuallyHiddenInput
          // accept="image/*;capture=camera"
          accept="image/png, image/jpeg"
          type="file"
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              if (e.target.files[0].size > 1024 * 8000) {
                alert("حجم فایل زیاد است. لطفا عکس با حجم کمتر انتخاب کنید");
                return;
              }
              // console.log(e.target.files[0].type);
              if (!e.target.files[0].type.startsWith("image")) {
                alert("لطفا یک عکس انتخاب کنید");
                return;
              }
              setFormState({
                ...formState,
                image: e.target.files[0],
              });
            }
          }}
        />
      </Button>

      <span >تصویر انتخاب شده</span>
      {formState.image && <span>{formState.image.name}</span>}

      <Typography>حجم فایل</Typography>
      {formState.image && (
        <Typography>
          {Number(formState.image.size / 1024).toFixed(0)} KB
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={async () => {
          if (
            // !formState.position?.latitude ||
            !formState.msisdn ||
            !formState.productName ||
            !formState.image
          ) {
            setShowAlert(true);
            return;
          }
          setShowAlert(false);

          let token = localStorage.getItem("token");
          let msisdn = localStorage.getItem("msisdn");
          if (!token || msisdn !== formState.msisdn) {
            setShowOtpDialog(true);
            return;
          }
          await startConversation();
        }}
      >
        ارسال
      </Button>
      <Button variant="contained" onClick={() => showConversationsList()}>
        مشاهده مکالمات قبلی
      </Button>
      {showAlert && (
        <ConfirmDialog title="ورود اطلاعات" onClose={() => setShowAlert(false)}>
          <Alert>
            <Typography>لطفا اطلاعات درخواستی را به درستی وارد کنید</Typography>
            <List>
              {/* {!formState.position?.latitude && (
                <ListItem>
                  {" "}
                  <Typography>موقعیت جغرافیایی</Typography>
                </ListItem>
              )} */}

              {(!formState.msisdn || !isValidMsisdn(formState.msisdn)) && (
                <ListItem>
                  <Typography>شماره موبایل</Typography>
                </ListItem>
              )}

              {!formState.productName && (
                <ListItem>
                  <Typography>نوع کشت</Typography>
                </ListItem>
              )}

              {!formState.damageType && (
                <ListItem>
                  <Typography>نوع آسیب</Typography>
                </ListItem>
              )}
            </List>
          </Alert>
        </ConfirmDialog>
      )}
      {showConfirm && (
        <ConfirmDialog
          title="ثبت با موفقیت انجام شد"
          content="برای مشاهده نتیجه کلیک کنید"
          onClose={() => {
            setShowConfirm(false);
            showChatPage(conversation?._id!);
          }}
        />
      )}

      {findUserLocation.loading && (
        <LoadingDialog
          title="موقعیت جغرافیایی"
          content="در حال دریافت موقعیت جغرافیایی"
        ></LoadingDialog>
      )}

      {loading && (
        <LoadingDialog title="در حال ثبت اطلاعات" content="لطفا منتظر باشید" />
      )}
      {showOtpDialog && (
        <OtpDialog
          msisdn={formState.msisdn!}
          onClose={() => setShowOtpDialog(false)}
          onMsisdnVerified={async (token: string) => {
            localStorage.setItem("msisdn", formState.msisdn!);
            // localStorage.setItem("token", token);
            setShowOtpDialog(false);
            await startConversation();
          }}
        />
      )}
    </Stack>
  );
}
