import { Fragment, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Countdown from "react-countdown";
import { Alert, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { sendOTP, verifyOTP } from "../apis";
import { isValidMsisdn } from "../helpers/functions";

export default function OtpDialog({
  msisdn,
  onMsisdnVerified,
  onClose,
}: {
  msisdn?: string;
  onMsisdnVerified: Function;
  onClose: Function;
}) {
  const [disableSendOtp, setDisableSendOtp] = useState<boolean>(
    msisdn ? isValidMsisdn(msisdn) : true
  );
  const [msisdnValue, setMsisdnValue] = useState(msisdn);
  const [currentStep, setCurrentStep] = useState(!msisdn ? 1 : 2);
  const [otpValue, setOtpValue] = useState("");
  const [countDownTime, setCountDownTime] = useState(0);
  const [countDownkey, setCountDownKey] = useState(Date.now());
  const [allowRequestOtp, setAllowRequestOtp] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (msisdnValue) {
        sendOTP(msisdnValue);
      }
    }
  }, []);
  return (
    <Fragment>
      <Button variant="outlined">تایید شماره موبایل</Button>
      <Dialog open={true} onClose={() => onClose()}>
        <DialogTitle>تایید شماره موبایل</DialogTitle>
        <Stepper activeStep={currentStep - 1}>
          <Step>
            <StepLabel>ورود شماره موبایل</StepLabel>
          </Step>

          <Step>
            <StepLabel>ورود کد تایید</StepLabel>
          </Step>
        </Stepper>
        {errorMsg !== "" && (
          <Alert severity="error" title="خطا">
            {errorMsg}
          </Alert>
        )}
        {currentStep === 1 && (
          <>
            <DialogContent>
              <DialogContentText>
                شماره موبایل خود را وارد کنید
              </DialogContentText>
              <TextField
                value={msisdnValue}
                autoFocus
                required
                margin="dense"
                id="msisdn"
                placeholder="09123456789"
                name="msisdn"
                label="شماره موبایل"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  let msisdn = e.target.value;
                  if (!isValidMsisdn(msisdn)) {
                    setDisableSendOtp(true);
                  } else {
                    setDisableSendOtp(false);
                  }
                  setMsisdnValue(msisdn);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={async () => {
                  try {
                    setDisableSendOtp(true);
                    let res = await sendOTP(msisdnValue!);
                    if (
                      res.status !== "ok" &&
                      res.status !== "codeAlreadySent"
                    ) {
                      setErrorMsg("خطا در ارسال پیام کوتاه");
                      return;
                    }
                    setCurrentStep(2);
                    setCountDownTime(Date.now() + 180000);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setDisableSendOtp(false);
                  }
                }}
                disabled={disableSendOtp}
              >
                مرحله بعد
              </Button>
            </DialogActions>
          </>
        )}
        {currentStep === 2 && (
          <>
            <DialogContent>
              <DialogContentText>
                لطفا کد ورودی که از طریق پیام کوتاه دریافت کرده اید را وارد کنید
              </DialogContentText>
              <TextField
                value={otpValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setOtpValue(newValue);
                  if (!newValue) {
                    setAllowSubmit(false);
                  } else {
                    setAllowSubmit(true);
                  }
                }}
                autoFocus
                required
                margin="dense"
                id="otp"
                name="otp"
                label="کد ورود"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setCurrentStep(1)}>
                تغییر شماره
              </Button>
              <Button
                variant="contained"
                disabled={!allowSubmit}
                onClick={async () => {
                  try {
                    setAllowSubmit(false);
                    let res = await verifyOTP(msisdnValue!, otpValue);
                    if (res.status === "ok") {
                      onMsisdnVerified(res.data.token);
                    } else {
                      setErrorMsg("کد وارد شده صحیح نمیباشد");
                    }
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setAllowSubmit(true);
                  }
                }}
              >
                تایید
              </Button>
              <Button
                variant="contained"
                disabled={!allowRequestOtp}
                onClick={async () => {
                  await sendOTP(msisdnValue!);
                  setCountDownTime(Date.now() + 180000);
                  setCountDownKey(Date.now());
                  setAllowRequestOtp(false);
                }}
              >
                <Typography sx={{ marginRight: 1 }}>ارسال مجدد</Typography>
                <Countdown
                  key={countDownkey}
                  onComplete={() => {
                    setAllowRequestOtp(true);
                  }}
                  date={countDownTime}
                  renderer={({ hours, minutes, seconds, completed }) => {
                    if (completed) {
                      return;
                    } else {
                      return (
                        <span>
                          {minutes}:{seconds}
                        </span>
                      );
                    }
                  }}
                />
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Fragment>
  );
}
