import axios from "axios";
const baseurl = process.env.REACT_APP_API_ENDPOINT;
let token = localStorage.getItem("token");
let api = axios.create({
  baseURL: baseurl,
  headers: { Authorization: `Bearer ${token}` },
});

export async function register(): Promise<string> {
  let res = await api.post("/register");
  let token = res.data.data.token;
  localStorage.setItem("token", token);
  api.defaults.headers["Authorization"] = `Bearer ${token}`;
  return token;
}

export async function sendOTP(msisdn: string) {
  let res = await api.post(`/otp/send`, { msisdn });
  return res.data;
}

export async function verifyOTP(msisdn: string, code: string) {
  let res = await api.post(`/otp/verify`, { msisdn, code });
  if (res.data.status === "ok") {
    let token = res.data.data.token;
    localStorage.setItem("token", token);
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
  }
  return res.data;
}

export async function startConversation(
  msisdn: string,
  latitude: string,
  longitude: string,
  productName: string,
  damageType: string,
  image: File
) {
  const form = new FormData();
  form.append("msisdn", msisdn);
  if (latitude) {
    form.append("latitude", latitude);
  }
  if (longitude) {
    form.append("longitude", longitude);
  }
  form.append("productName", productName);
  form.append("damageType", damageType);
  form.append("image", image);

  let result = await api.post("/conversation", form);
  return result.data;
}

export async function getConversations() {
  console.log("log", api.defaults.headers.common);
  let res = await api.get("conversations");
  return res.data;
}

export async function getConversation(conversationId: string) {
  let res = await api.get(`conversations/${conversationId}`);
  return res.data;
}

export async function replyToConversation(
  conversationId: string,
  content: string
) {
  await api.post(`/conversations/${conversationId}/reply`, {
    userMessage: content,
  });
}

export async function finishConversation(conversationId: string) {
  await api.post(`/conversations/${conversationId}/finish`);
}
