import { Container  , Typography } from "@mui/material";

const StyledTypo = (props: any) => (
  <Typography sx={{ lineHeight: 2 }}>{props.children}</Typography>
);
export default function Docs() {
  return (
    <Container>
      {/* <StyledTypo>راهنمای استفاده</StyledTypo> */}

      <StyledTypo variant="h5">
        اپلیکیشنی که بیماری های گیاهی را شناسایی می کند و مشکلات گیاهی را تشخیص
        می دهد
      </StyledTypo>
      <StyledTypo>
        بازرسی بصری نقش مهمی در شناسایی بیماری های گیاهی و تشخیص مشکلات گیاه
        ایفا می کند، زیرا آزمایش ها و ابزار آزمایشگاهی عملا برای تشخیص روزانه
        کاربردی ندارند . با توجه به هزینه ها و زمان بر بودن روش های تشخیص بیماری
        و کمبودها و مشکلات گیاهان و مزارع در حال حاضر غالب کشاورزان جزء و کشت و
        صنعت های بزرگ براساس علائمی که روی گیاهان دیده می شود و سایر اطلاعات با
        کمک متخصصین این حوزه تصمیم می گیرند. از آنجا که بازدید حضوری مزارع و
        گیاهان زمان بر است و مشاوران کشاورزی با هزینه بالا کشاورزان را در
        شناسایی و درمان بیماری های گیاهی کمک می کنند و گاها ممکن است در دسترس
        نباشند ما با کمک فناوری های جدید اپلیکیشنی مبتنی بر هوش مصنوعی که
        بیماری‌های گیاهی را شناسایی می‌کند را توسعه داده ایم .
      </StyledTypo>

      <StyledTypo>
        در سال‌های اخیر، محصولات نرم‌افزاری می‌توانند وظایف مبتنی بر بازدید
        حضوری را با دقت بالایی به عهده بگیرند. چرا این فناوری را برای شناسایی
        بیماری های گیاهی و مشاوره زراعی استفاده نمی کنید؟ استفاده گسترده از
        دستگاه‌های تلفن همراه این امکان را فراهم کرده است که به نوعی بازدید
        حضوری را خود کشاورز بتواند انجام دهد و از آنجا که سیستم های هوش مصنوعی و
        پردازش تصویر و تصمیم ساز نرم افزاری قابلیت تشخیص مشکلات بیماری و کمبود
        یا آفت را با دقت قابل قبولی انجام میدهند سامانه هوش مصنوعی و سیستم خبره
        با دریافت تصویر تهیه شده توسط اپلیکیشن نصب شده روی گوشی و اطلاعات دیگری
        که ممکن است از کشاورز به صورت هوشمندانه اپلیکیشن از کشاورز بپرسد مشکل را
        تشخیص و درمان را ارائه میدهد
      </StyledTypo>

      <StyledTypo>
        Keshtdiag یک برنامه اندروید و iOS است که برای انجام این کار توسعه یافته
        است. این برنامه کاربردی به کشاورزان کمک می کند تا حفاظت از گیاهان را
        بصورت پایدارتر مدیریت کنند زیرا شناسایی بیماری جزء مهمی در روال حفاظت از
        گیاه است.
      </StyledTypo>

      <StyledTypo>
        وقتی از مزرعه خود مراقبت می کنید ، keshtdiag مشکلات گیاه را بصورت فوری
        تشخیص داده و راه حل ارائه می دهد که نیاز به اتلاف وقت برای شناسایی را
        کاهش می دهد. علاوه بر این، تصاویر ضبط شده می توانند توسط دیگران بررسی
        شده و در آینده به عنوان مرجع مورد استفاده قرار گیرند. کشاورزان که تشخیص
        دقیق مشکلات مزرعه برایشان دشوار است از این ابزار به عنوان دستیار یا راهی
        برای دریافت نظر دوم در صورت وجود شک و تردید استفاده می کنند. این امر به
        ویژه در مواقعی مهم است که سایر منابع مشاوره در دسترس نباشند، مانند
        کشورهایی که در آنها نسبت تعداد تولیدکنندگان به کشاورزان بسیار زیاد است.
      </StyledTypo>

      <StyledTypo variant="h6">
        چرا تشخیص دقیق مشکلات گیاهی ضروری است؟
      </StyledTypo>

      <StyledTypo>
        هر پاتوژن یا آفت یا کمبود مواد مغذی و ریز مغذی به درمان متفاوتی نیاز
        دارد. علاوه بر این، پاتوژن ها می توانند در برخی مناطق جغرافیایی به برخی
        از درمان ها مقاومت نشان دهند. این اطلاعات باید در هنگام توصیه درمانی در
        نظر گرفته شود.
      </StyledTypo>

      <StyledTypo>
        هنگامی که مشکلات از قبل در طرح مشاهده شده است، شناسایی دقیق می تواند به
        جلوگیری از گسترش کمک کند. راهبردی که اقدامات باید بر اساس آن اعمال شود
        به شناسایی دقیق علت بستگی دارد.
      </StyledTypo>

      <StyledTypo>
        وقتی صحبت از پیشگیری به میان می آید، پرورش دهندگان باید پیامدهای آن را
        برای فصول بعدی در نظر بگیرند. هنگامی که انتظار می رود آفت در خاک یا
        بقایای گیاهی زنده بماند، ممکن است تناوب زراعی یا درمان خاک توصیه شود. در
        مورد یک بیماری ویروسی، ممکن است توصیه شود که گونه های مقاوم به ویروس را
        در فصول بعدی پس از شناسایی ویروس در منطقه کاشت کنید. عوامل دیگری که نیاز
        به آماده سازی خاک قبل از کاشت دارند عبارتند از خاک شور، PH نامناسب خاک،
        وجود نماتد و غیره. در تمام مثال های بالا، شناسایی دقیق مشکل باید به دست
        آید تا از ضررهای آتی جلوگیری شود.
      </StyledTypo>
    </Container>
  );
}
