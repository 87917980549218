import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmDialog({
  title,
  content,
  onClose,
  children,
}: {
  title: string;
  content?: string;
  onClose: Function;
  children?: React.ReactNode;
}) {
  return (
    <React.Fragment>
      <Dialog
        open={true}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center" id="alert-dialog-description">
            {content ?? children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} autoFocus>
            بستن
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
