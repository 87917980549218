import React from "react";
import "./App.css";
import { Container, createTheme, ThemeProvider } from "@mui/material";
import MainPage from "./components/MainPage";
import "vazir-font/dist/font-face.css";

const theme = createTheme({
  typography: {
    fontFamily: ["vazir", "tahoma"].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ textAlign: "right", direction: "rtl" }}>
        <MainPage></MainPage>
      </Container>
    </ThemeProvider>
  );
}

export default App;
