import { useEffect, useState } from "react";
import * as api from "../apis";
import {
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  ConversationStatusEnum,
  IConversation,
  MessageFromEnum,
} from "./interfaces";
import LoadingDialog from "./LoadingDialog";
export default function Chat(props: {
  conversationId: string;
  changeCurrentView: Function;
}) {
  const [conversation, setConversation] = useState<IConversation | null>(null);
  const [userReply, setUserReply] = useState<string>("");
  const [loading, setLoading] = useState(false);
  async function getConversation() {
    const result = await api.getConversation(props.conversationId);
    setConversation(result.data.conversation);
  }
  useEffect(() => {
    getConversation();
  }, []);
  return (
    <Container>
      {/* <Box sx={{ display: "flex" }}> */}
      {conversation &&
        conversation.userGptMessages.map((message) => (
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              textAlign: "right",
              padding: 2,
              border: "2px solid #000",
              borderRadius: 4,
              margin: 2,
              flexDirection:
                message.from === MessageFromEnum.SYSTEM ? "row" : "row-reverse",
              justifyContent:
                message.from === MessageFromEnum.SYSTEM
                  ? "flex-start"
                  : "flex-end",
            }}
          >
            {/* <Typography>{message.from}</Typography> */}
            <Typography>{message.content}</Typography>
          </Card>
        ))}
      {/* </Box> */}
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            placeholder="پاسخ شما..."
            fullWidth
            value={userReply}
            onChange={(e) => setUserReply(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            disabled={
              conversation?.status !==
              ConversationStatusEnum.NEEDS_USER_RESPONSE
            }
            onClick={async () => {
              try {
                setLoading(true);
                await api.replyToConversation(conversation?._id!, userReply);
                setUserReply("");
                await getConversation();
              } catch (error) {
                console.log(error);
              } finally {
                setLoading(false);
              }
            }}
          >
            ارسال
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            disabled={
              conversation?.status !==
              ConversationStatusEnum.NEEDS_USER_RESPONSE
            }
            variant="contained"
            onClick={async () => {
              try {
                setLoading(true);
                await api.finishConversation(conversation?._id!);
                setUserReply("");
                await getConversation();
              } catch (error) {
                console.log(error);
              } finally {
                setLoading(false);
              }
            }}
          >
            جواب خود را دریافت کردم
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              props.changeCurrentView("list");
            }}
          >
            بازگشت
          </Button>
        </Grid>
      </Grid>

      {loading && <LoadingDialog />}
    </Container>
  );
}
