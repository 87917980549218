import { AppBar, Container, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as api from "../apis";

import ConversationsList from "./ConversationsList";
import Chat from "./Chat";
import { SubmitForm } from "./SubmitForm";
import ResponsiveAppBar from "./AppBar";
import AboutUs from "./AboutUs";
import Docs from "./Docs";
import Docs2 from "./Docs2";
interface IPosition {
  latitude: number;
  longitude: number;
}

export interface IFormData {
  position?: IPosition;
  msisdn?: string;
  productName?: string;
  damageType?: string;
  image?: File;
}
export enum PagesEnum {
  SUBMIT = "submit",
  LIST = "list",
  CHAT = "chat",
  ABOUT_US = "aboutUs",
  DOCS = "docs",
  DOCS2 = "docs2",
}
export default function MainPage() {
  //   const [position, setPosition] = useState<IPosition | null>(null);
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);
  useEffect(() => {
    async function init() {
      // let token = localStorage.getItem("token");
      // if (!token) {
      //   await api.register();
      // }
    }
    init();
  }, []);
  const [currentView, setCurrentView] = useState<PagesEnum>(PagesEnum.SUBMIT);
  return (
    <Container>
      <ResponsiveAppBar setCurrentView={setCurrentView} />
      {currentView === "submit" && (
        <Container>
          <SubmitForm
            showConversationsList={() => setCurrentView(PagesEnum.LIST)}
            showChatPage={(conversationId: string) => {
              setSelectedConversationId(conversationId);
              setCurrentView(PagesEnum.CHAT);
            }}
          />
        </Container>
      )}
      {currentView === "list" && (
        <ConversationsList
          changeCurrentView={(view: PagesEnum) => setCurrentView(view)}
          currentView={currentView}
          setCurrentView={setCurrentView}
          setSelectedConversationId={setSelectedConversationId}
        />
      )}
      {currentView === "chat" && (
        <Chat
          changeCurrentView={(view: PagesEnum) => setCurrentView(view)}
          conversationId={selectedConversationId!}
        />
      )}
      {currentView === PagesEnum.ABOUT_US && <AboutUs />}
      {currentView === PagesEnum.DOCS && <Docs />}
      {currentView === PagesEnum.DOCS2 && <Docs2 />}
    </Container>
  );
}
