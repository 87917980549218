import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { PagesEnum } from "./MainPage";
import OtpDialog from "./OtpDialog";
import OtpStepper from "./OtpStepper";

interface IPage {
  title: string;
  key: string;
}
const pages: Array<IPage> = [
  {
    key: "home",
    title: "صفحه اصلی",
  },
  {
    key: "aboutUs",
    title: "درباره ما",
  },
  {
    key: "docs",
    title: "سوالات پر تکرار",
  },
  {
    key: "docs2",
    title: "راهنمای استفاده",
  },
];
const settings = ["خروج"];

function ResponsiveAppBar({ setCurrentView }: { setCurrentView: Function }) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const [showOtpDialog, setShowOtpDialog] = React.useState(false);
  let token = localStorage.getItem("token");
  const [isLogin, setIsLogin] = React.useState(!!token);

  const LoginButton = function (props: any) {
    if (!isLogin) {
      return (
        <Button
          sx={{ color: props.color ?? "black" }}
          key="login"
          onClick={() => {
            setShowOtpDialog(true);
          }}
        >
          ورود
        </Button>
      );
    } else {
      return (
        <Button
          key="logout"
          sx={{ color: props.color ?? "black" }}
          onClick={() => {
            localStorage.removeItem("msisdn");
            localStorage.removeItem("token");
            window.location.reload();
          }}
        >
          خروج
        </Button>
      );
    }
  };
  const handleCloseNavMenu = (page: IPage) => {
    setAnchorElNav(null);
    if (page.key === "home") {
      setCurrentView(PagesEnum.SUBMIT);
    }
    if (page.key === "aboutUs") {
      setCurrentView(PagesEnum.ABOUT_US);
    }
    if (page.key === "docs") {
      setCurrentView(PagesEnum.DOCS);
    }

    if (page.key === "docs2") {
      setCurrentView(PagesEnum.DOCS2);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ marginBottom: 2 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },

              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          ></Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.key}
                  onClick={() => handleCloseNavMenu(page)}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
              <MenuItem key="loginBtn">
                <LoginButton />
              </MenuItem>
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
          <Typography
            variant="caption"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              fontFamily: "Vazir",
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              // letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            بسامد نگار غرب
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.key}
                onClick={() => {
                  handleCloseNavMenu(page);
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.title}
              </Button>
            ))}
            <LoginButton color="white" />
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>

      {showOtpDialog && (
        <OtpDialog
          onClose={() => setShowOtpDialog(false)}
          onMsisdnVerified={() => {
            setIsLogin(true);
            setShowOtpDialog(false);
          }}
        />
      )}
    </AppBar>
  );
}
export default ResponsiveAppBar;
