import { Container, Typography } from "@mui/material";

export default function AboutUs() {
  return (
    <Container>
      <Typography variant="h6">درباره ما</Typography>
      <Typography>شماره پشتیبانی: 09057083702</Typography>
      <img src="./about-us.jpg" alt="about-us" style={{ width: "100%" }} />
    </Container>
  );
}
