import { Container, Typography } from "@mui/material";

export default function Docs2() {
  return (
    <Container sx={{ textAlign: "center" }}>
      <Typography variant="h5">نحوه استفاده از کشت بین</Typography>
      <Typography>دریافت و نصب و استفاده از اپلیکیشن رایگان است</Typography>
      <Typography>
        .ممکن است اپلیکیشن اجازه دسترسی به موقعیت مکانی و دوربین را از شما
        بخواهد.
      </Typography>
      <Typography>
        حتما هنگام استفاده از اپلیکیشن سیستم موقعیت یاب گوشی (GPS) را روشن کنید
      </Typography>
      <Typography>ابتدا نوع کشت خود را انتخاب کنید</Typography>
      <Typography>سپس موضع آسیب دیده را انتخاب کنید</Typography>
      <Typography>
        موضع آسیب دیده را از گیاه جدا کرده و روی پس زمینه سفید بگذارید و با
        استفاده از اپلیکیشن عکس بگیرید
      </Typography>
      <Typography>
        در گرفتن عکس دقت کنید موضع آسیب دیده در مرکز عکس باشد
      </Typography>
      <Typography>
        عکس را از نزدیکترین فاصله ممکن به نحوی کل موضع در عکس قرار داشته باشد
        بگیرید
      </Typography>
      <Typography>عکس با رزولوشن و وضوح بالا گرفته شود بهتر است</Typography>
      <Typography>
        دکمه ارسال ازاطلاعات و دریافت تشخیص سامانه را بزنید
      </Typography>
      <Typography>
        منتظر بمانید تا سامانه تحلیل های خود را انجام دهد و نتیجه در باکس پایین
        اپلیکیشن به شما ارائه نماید
      </Typography>
      <Typography>
        ممکن است سیستم خبره قبل از ارائه تشخیص، چند سوال هوشمندانه از شما در
        زمینه گیاه بپرسد آنها را با دقت جواب دهید
      </Typography>
    </Container>
  );
}
