import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";

export default function LoadingDialog({
  title,
  content,
}: {
  title?: string;
  content?: string;
}) {
  return (
    <React.Fragment>
      <Dialog
        open={true}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          {title ?? "در حال ارسال اطلاعات"}
        </DialogTitle>
        <DialogContent>
          <CircularProgress />
          <DialogContentText id="dialog-description">
            {content ?? "لطفا کمی صبر کنید"}
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
