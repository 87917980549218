import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as api from "../apis";
import { ConversationStatusEnum, IConversation } from "./interfaces";
import { Button } from "@mui/material";
const baseurl = process.env.REACT_APP_API_ENDPOINT;

function mapStatusToText(status: ConversationStatusEnum): string {
  switch (status) {
    case ConversationStatusEnum.IN_PROGRESS:
      return "در حال بررسی";
    case ConversationStatusEnum.NEEDS_CLASSIFICATION:
      return "نیاز به پردازش تصویر";
    case ConversationStatusEnum.NEEDS_LLM:
      return "نیاز به پاسخ دهی سیستم";
    case ConversationStatusEnum.NEEDS_USER_RESPONSE:
      return "منتظر پاسخ شما";
    case ConversationStatusEnum.RESOLVED:
      return "حل شده";
  }
}
export default function ConversationsList({
  currentView,
  setCurrentView,
  changeCurrentView,
  setSelectedConversationId,
}: {
  currentView: string;
  setCurrentView: Function;
  changeCurrentView: Function;
  setSelectedConversationId: Function;
}) {
  const [conversations, setConversations] = React.useState<
    IConversation[] | null
  >(null);
  React.useEffect(() => {
    async function getConversations() {
      let result = await api.getConversations();
      setConversations(result.data.conversations);
    }
    getConversations();
  }, []);
  return (
    <>
      {currentView === "list" && (
        <>
          <Button
            fullWidth
            variant="contained"
            sx={{ marginBottom: 5, marginTop: 2 }}
            onClick={() => {
              changeCurrentView("submit");
            }}
          >
            بازگشت به فرم ارسال
          </Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>شماره موبایل</TableCell>
                  <TableCell align="right">عرض جغرافیایی</TableCell>
                  <TableCell align="right">طول جغرافیایی</TableCell>
                  <TableCell align="right">نوع محصول</TableCell>
                  <TableCell align="right">نوع آسیب</TableCell>
                  <TableCell align="right">وضعیت</TableCell>
                  <TableCell align="right">تصویر</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conversations &&
                  conversations.map((conversation: IConversation) => (
                    <TableRow
                      onClick={() => {
                        setSelectedConversationId(conversation._id);
                        setCurrentView("chat");
                      }}
                      hover
                      key={conversation._id}
                      sx={{
                        cursor: "pointer",
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">{conversation.msisdn}</TableCell>
                      <TableCell component="th" scope="row">
                        {conversation.latitude}
                      </TableCell>
                      <TableCell align="right">
                        {conversation.longitude}
                      </TableCell>
                      <TableCell align="right">
                        {conversation.productName}
                      </TableCell>
                      <TableCell align="right">
                        {conversation.damageType}
                      </TableCell>
                      <TableCell align="right">
                        {mapStatusToText(conversation.status)}
                      </TableCell>
                      <TableCell align="right">
                        <img
                          src={`${baseurl}/${conversation.image.path}`}
                          alt="تصویر ارسالی"
                          width={200}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
